import { useStaticQuery, graphql } from 'gatsby';

export const usePortfolioData = () => {
    const {
        malltv,
        vogue,
        netvision,
        vse,
        hankook,
        mercedes,
        phb,
        golftour,
        brit,
        burda,
        xiaomi,
        dell,
    } = useStaticQuery(graphql`
        query {
          malltv: file(relativePath: { eq: "portfolio/malltv.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          vogue: file(relativePath: { eq: "portfolio/vogue-cs.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          netvision: file(relativePath: { eq: "portfolio/netvision.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          vse: file(relativePath: { eq: "portfolio/vse.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          hankook: file(relativePath: { eq: "portfolio/hankook.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          mercedes: file(relativePath: { eq: "portfolio/mercedes.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          phb: file(relativePath: { eq: "portfolio/phb.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          golftour: file(relativePath: { eq: "portfolio/golf-tour.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          brit: file(relativePath: { eq: "portfolio/brit.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          brit: file(relativePath: { eq: "portfolio/brit.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          burda: file(relativePath: { eq: "portfolio/burda.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          xiaomi: file(relativePath: { eq: "portfolio/xiaomi.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          dell: file(relativePath: { eq: "portfolio/dell.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
        }
    `);

    return [
        {
            title: 'Mall Group',
            desc: 'Komplexní nastavení a implementace obchodní strategie & vstup na trh MALLTV CZ/SK',
            img: malltv,
        },
        {
            title: 'VOGUE CS',
            desc: 'Digital&Sales strategie společnosti',
            img: vogue,
        },
        {
            title: 'NetVision agency',
            desc: 'Založení a nastavení partnerské společnosti Netvision agency (sales,mkt,provoz,BI)',
            img: netvision,
        },
        {
            title: 'VŠE',
            desc: 'Mentoring studentů v oblasti osobního rozvoje',
            img: vse,
        },
        {
            title: 'Hankook',
            desc: 'Run-up projekt při launchi MallTV',
            img: hankook,
        },
        {
            title: 'Mercedes Benz',
            desc: 'Projekt na míru pro Mercedes pod záštitou Bez Frází',
            img: mercedes,
        },
        {
            title: 'P.B.H. ecology',
            desc: 'Brand identita pro novou společnost s nanotechnologií',
            img: phb,
        },
        {
            title: 'DEVELOPMENT TOUR',
            desc: 'Kompletní build-up prestižní golfové tour pro developery',
            img: golftour,
        },
        {
            title: 'Brit',
            desc: 'Kreativní edukativní seriál na míru pro pejskaře ',
            img: brit,
        },
        {
            title: 'Burda International',
            desc: 'Restrukturalizace a implementace digitální strategie svěřených titulů',
            img: burda,
        },
        {
            title: 'Xiaomi',
            desc: 'Future komediální sit-com z prostředí SMART HOME',
            img: xiaomi,
        },
        {
            title: 'Dell',
            desc: 'Partnership a PP v pořadu Rozum v troubě (MALLTV) ',
            img: dell,
        },
    ]
};
