import React from 'react'
import {usePortfolioData} from './query';
import Img from 'gatsby-image';

import './portfolio.scss'

const Portfolio = () => {
    const data = usePortfolioData();

    return (
        <div className='c-portfolio' data-animate-trigger>
            <ul className='c-portfolio__inner'>
                {data.map((item, index) => (
                    <li key={index} className='c-portfolio__item'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: index / 10})}
                    >
                        <div className='c-portfolio__item-inner'>
                            {item.img && (
                                <div className="c-portfolio__item-img">
                                    <Img className="o-image" fluid={item.img.childImageSharp.fluid} imgStyle={{objectFit: 'cover'}}/>
                                </div>
                            )}
                            <div className='c-portfolio__item-body'>
                                <h3 className='c-portfolio__item-title'>{item.title}</h3>
                                <p className='c-portfolio__item-desc'>{item.desc}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Portfolio;
