import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'gatsby';
import CountUp from 'react-countup';
import {useImages} from './images';
import Img from 'gatsby-image';

import './who-we-are.scss';
import {isViewportReached} from '../../../helpers/isViewportReached';

const WhoWeAre = () => {
    const [showCounters, setShowCounters] = useState(false);
    const wrapperElement = useRef();
    const {martin, martinSignature} = useImages();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const el = wrapperElement.current;
            if (!showCounters && isViewportReached(el)) {
                setShowCounters(true);
            }
        })
    }, []);

    return (
        <div className='c-who-we-are' ref={wrapperElement} data-animate-trigger>
            <div className='c-who-we-are__inner'>
                <div className="c-who-we-are__numbers-wrap"
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1})}
                >
                    <div className="c-who-we-are__numbers">
                        <div className="c-who-we-are__number">
                            <span className="c-who-we-are__number-value">
                                {showCounters ? <CountUp end={150} duration={3} delay={.1} suffix={'+'}/> : 0}
                            </span>
                            <p className="c-who-we-are__number-label">Spolupracujících klientů z řad corpotace, SME & SMBL</p>
                        </div>
                        <div className="c-who-we-are__number">
                            <span className="c-who-we-are__number-value">
                                {showCounters ? <CountUp end={21} duration={3} delay={.1} /> : 0}
                            </span>
                            <p className="c-who-we-are__number-label">Expertů a konzultantů v oblasti strategie, marketingu a salesu</p>
                        </div>
                    </div>
                </div>

                <div className='c-who-we-are__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .1})}
                >
                    <h2 className='c-who-we-are__item-heading o-heading o-heading--h2'>Jsme strategičtí</h2>
                    <p className='c-who-we-are__item-content'>Analyzujeme firmy a jejich interní strategii i strategii k jejím klientům. Vymýšlíme a pomáháme realizovat akční plán na změny, kterým transformujeme firmy a pomáháme jim růst. Jsme partnery pro nastavování procesů. V naší práci využíváme propojení levé a pravé hemisféry mozku – logickou i kreativní. Jsme flexibilní. Tvoříme vize, umíme naslouchat, porozumět a jsme všímaví. Rádi se učíme a ještě raději učíme Vás. Nejste v tom sami, nechte si poradit.</p>
                </div>
                <div className='c-who-we-are__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
                >
                    <h2 className='c-who-we-are__item-heading o-heading o-heading--h2'>Jsme markeťáci</h2>
                    <p className='c-who-we-are__item-content'>V digitálním marketingu jsme odborníky a inovátory. Vlastníme mnoho nástrojů, se kterými pomáháme efektivně investovat peníze do kampaní. Jsme vizionáři, kreativci, vývojáři i mentoři v nastavování dlouhodobé marketingové strategie. Čelíme novým trendům. Máme orientaci na trh a řízení skrze Vaše zákazníky. Potřeby Vašich zákazníků jsou pro nás na prvním místě. Orientujeme se na chování Vaší konkurence.
                        Klademe důležitost na upevnění vztahu se zaměstnanci, dodavateli, distributory a investory. Hledáme cesty k novým obchodním příležitostem. Specializujeme se na marketingové plánování, budování značky i komunikační politiku.
                    </p>
                </div>
                <div className='c-who-we-are__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .3})}
                >
                    <h2 className='c-who-we-are__item-heading o-heading o-heading--h2'>Jsme tvůrci a kreativci</h2>
                    <p className='c-who-we-are__item-content'>Poradíme si se vznikem celé identity včetně jejího vstupu na trh. Baví nás velké výzvy pro velké značky. Inovujeme vlastní produkty a vyvíjíme produkty na míru pro naše klienty. Oči máme stále otevřené a hledáme potřeby budoucnosti našich klientů. Přeměňujeme nápady a idei do reality. Žijeme tvůrčí činností v oblasti reklamy a marketingu.</p>
                </div>
                <div className='c-who-we-are__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                >
                    <h2 className='c-who-we-are__item-heading o-heading o-heading--h2'>Jsme <span className='o-highlight-red'>Business Elements</span></h2>
                    <p className='c-who-we-are__item-content'>Jsme rozmanitá skupina dynamických lidí, která se vcítí do Vašich potřeb a vyřeší je s radostí a k Vaší maximální spokojenosti.</p>
                </div>

                <p className='c-who-we-are__button-container'
                   data-animate-target
                   data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                   data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                >
                    <Link to={'/nas-pribeh'} className='o-button'>Náš příběh</Link>
                </p>
            </div>

            <div className="c-who-we-are__founder-container"
                 data-animate-target
                 data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                 data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .6})}>
                <div>
                    <div className="c-who-we-are__founder-img-inner">
                        <div className="c-who-we-are__founder-img">
                            <Img className='o-image' fluid={martin.childImageSharp.fluid} />
                        </div>
                    </div>
                </div>
                <div className="c-who-we-are__founder-body">
                    <h3 className="c-who-we-are__founder-title">Naše <span className='o-highlight-red'>filosofie</span>, naše poslání</h3>
                    <p className="c-who-we-are__founder-cite">&bdquo;Na základě našich dlouholetých zkušeností jsme vytvořili společnost, která je orientovaná na Vaše potřeby a jejich naplnění.  Důvěra, radost z toho co děláme, inovace a efektivita - na těchto hodnotách stojí naše firemní kultura. Našim cílem je najít nejefektivnější způsob jak posunout Vaší společnost společně kupředu. Nebojíme se žádné výzvy ani překážky, jedině tak dokážeme společně růst.&ldquo;</p>
                    <div className="c-who-we-are__founder-footer">
                        <div className="c-who-we-are__founder-signature">
                            <Img className='o-image' fluid={martinSignature.childImageSharp.fluid} />
                        </div>
                        <div>
                            <p className="c-who-we-are__founder-name">Martin Vrána</p>
                            <p className="c-who-we-are__founder-pos">Founder & Managing Director</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre;
