import React from 'react';
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image';

import './contact.scss'
import MapImg from '../../images/map.jpg';

const Contact = () => {
    const data = useStaticQuery(graphql`
        query {
          adform: file(relativePath: { eq: "partners/adform.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          ahrefs: file(relativePath: { eq: "partners/ahrefs.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          fbBusiness: file(relativePath: { eq: "partners/facebook-for-business.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          gWords: file(relativePath: { eq: "partners/google-words.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          gAna: file(relativePath: { eq: "partners/google-analytics.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          gPartner: file(relativePath: { eq: "partners/google-partner.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          seznamPartner: file(relativePath: { eq: "partners/seznam-partner.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          webtop100: file(relativePath: { eq: "partners/webtop100.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `);

    return (
        <div className='c-contact'>
            <div data-animate-trigger>
                <div className='c-contact__map'
                     data-animate-target
                     data-animate-from={JSON.stringify({opacity: 0})}
                     data-animate-to={JSON.stringify({opacity: 1, delay: .2, duration: 1})}
                >
                    <div className='c-contact__map-img-wrap'>
                        <img src={MapImg} alt="" className='c-contact__map-img'/>
                    </div>
                    <div className='c-contact__office c-contact__office--first'>
                        <p className='c-contact__office-title'>Pobočka Karlín</p>
                        <p className='c-contact__office-address'>
                            Pernerova 697/35 <br/>
                            Praha 8 - Karlín <br/>
                            186 00
                        </p>
                        <a href="https://www.google.com/maps/search/Pernerova+697%2F35+Praha+8+-+Karl%C3%ADn+186+00/@50.0909587,14.4505475,17z/data=!3m1!4b1" target="_blank" rel="nofollow noreferrer" className='c-contact__office-button'>Zobrazit na mapě</a>
                    </div>
                    <div className='c-contact__office c-contact__office--second'>
                        <p className='c-contact__office-title'>Pobočka Štěrboholy</p>
                        <p className='c-contact__office-address'>
                            Ústřední 388/18 <br/>
                            Praha 10 - Štěrboholy <br/>
                            102 00
                        </p>
                        <a href="https://www.google.com/maps/place/%C3%9Ast%C5%99edn%C3%AD+388,+102+00+Praha-%C5%A0t%C4%9Brboholy/@50.0714623,14.5484921,17z/data=!3m1!4b1!4m5!3m4!1s0x470b92971db252f5:0xca3687b9c33469dc!8m2!3d50.0714623!4d14.5506808" target="_blank" rel="nofollow noreferrer" className='c-contact__office-button'>Zobrazit na mapě</a>
                    </div>
                </div>
            </div>

            <div className='c-contact__content' data-animate-trigger>
                <ul className='c-contact__list'>
                    <li className='c-contact__item'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                    >
                        <p className='c-contact__label'>Telefon</p>
                        <p className='c-contact__value'><a className='o-link' href="tel:+420771208858">+420 771 208 858</a></p>
                    </li>
                    <li className='c-contact__item'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .6})}
                    >
                        <p className='c-contact__label'>E-mail</p>
                        <p className='c-contact__value'><a className='o-link' href="mailto:info@businesselements.cz">info@businesselements.cz</a></p>
                    </li>
                </ul>

                <p className='c-contact__label'
                   data-animate-target
                   data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                   data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                >Fakturační adresa</p>
                <ul className='c-contact__list'>
                    <li className='c-contact__item'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                    >
                        <p className='c-contact__address'>
                            MV Business Elements, s.r.o.<br/>
                            Pernerova 697/35,<br/>
                            Praha-Karlín, 186 00 Praha 8<br/>
                            <strong>IČ:</strong> 09191895 | <strong>DIČ:</strong> CZ09191895
                        </p>
                    </li>
                    <li className='c-contact__item'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: 1})}
                    >
                        <p className='c-contact__record'>
                            Společnost je zapsaná v obchodním rejstříku Městského soudu v Praze, oddíl C, vložka 332327
                        </p>
                    </li>
                </ul>
            </div>

            <div className="c-contact__partners" data-animate-trigger>
                <ul className='c-contact__partners-list'>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.adform.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 5., delay: .1})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.ahrefs.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .2})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.fbBusiness.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .3})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.gWords.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .4})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.gAna.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .5})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.gPartner.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .6})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.seznamPartner.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                    <li className='c-contact__partner'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: .5, delay: .7})}
                    >
                        <Img className='c-contact__partner-image' fluid={data.webtop100.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default Contact;
