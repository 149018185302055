import {graphql, useStaticQuery} from 'gatsby';

export const useImages = () => {
    return useStaticQuery(graphql`
    query {
      martin: file(relativePath: { eq: "martin-vrana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      martinSignature: file(relativePath: { eq: "martin-signature.png" }) {
        childImageSharp {
          fluid(maxWidth: 190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `);
};
