import React from "react"
import Layout from '../components/Layout';
import Section from '../components/Section';
import Hero from '../components/homepage/Hero';
import WhoWeAre from '../components/homepage/WhoWeAre';
import Access from '../components/homepage/Access';
import Services from '../components/homepage/Services';
import Portfolio from '../components/homepage/Portfolio';
import Career from '../components/homepage/Career';
import Contact from '../components/Contact';
import CTAContact from '../components/CTAContact';
import SEO from '../components/seo';

const Index = () => {

    return (
        <Layout>
            <SEO title="Business Elements | Digitalní marketingová agentura v Praze" />
            <Hero />

            <div data-header-schema={'dark'}>
                <Section heading={'Kdo jsme'} id={'kdo-jsme'}>
                    <WhoWeAre />
                </Section>

                <Section heading={'Přístup'} id={'pristup'}>
                    <Access />
                </Section>

                <Section heading={'Služby'} id={'sluzby'}>
                    <Services />
                </Section>

                <Section heading={'Portfolio'} id={'portfolio'}>
                    <Portfolio />
                </Section>

                <Section heading={'Kariéra'} id={'kariera'}>
                    <Career />
                </Section>

                <Section heading={'Kontakt'} id={'kontakt'}>
                    <Contact />
                </Section>

                <CTAContact />
            </div>
        </Layout>
    )
}

export default Index
