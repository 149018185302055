import React from 'react'

import './access.scss'

const Access = () => {

    return (
        <div className='c-access' data-animate-trigger>
            <ol className='c-access__inner'>
                <li className='c-access__item'
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
                >
                    <span className='c-access__item-number'>1</span>
                    <div>
                        <h2 className='c-access__item-heading o-heading o-heading--h2'>Zakládáme si na našich hodnotách</h2>
                        <p className='c-access__item-content'>Naše práce nás baví. Pracujeme vždy v týmu, který je pro nás důležitý. Každým krokem naplňujeme naší vizi a misi. Věříme si a zakládáme si na důvěře se zákazníky. Společně vytváříme synergii všech elementů ve firmách tak, aby naplnily své cíle. Jsme BUSINESS ELEMENTS.</p>
                    </div>
                </li>
                <li className='c-access__item'
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                >
                    <span className='c-access__item-number'>2</span>
                    <div>
                        <h2 className='c-access__item-heading o-heading o-heading--h2'>Business partneři</h2>
                        <p className='c-access__item-content'>Jsme nejen obchodníci, ale hlavně partnery našich klientů. Z každé situace vyjdeme jako vítězové společně. Dlouhodobé vztahy a důvěra jsou pro nás stěžejní. Všímavostí a bystrostí objevujeme nové cesty pro naše zákazníky.</p>
                    </div>
                </li>
                <li className='c-access__item'
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .6})}
                >
                    <span className='c-access__item-number'>3</span>
                    <div>
                        <h2 className='c-access__item-heading o-heading o-heading--h2'>Inovujeme</h2>
                        <p className='c-access__item-content'>Každý den je pro nás inovací, kterou hledáme, budujeme, vymýšlíme. Nestojíme na místě, zítra je pro nás dnes.</p>
                    </div>
                </li>
                <li className='c-access__item'
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                >
                    <span className='c-access__item-number'>4</span>
                    <div>
                        <h2 className='c-access__item-heading o-heading o-heading--h2'>Analyzujeme Data. Data. Data.</h2>
                        <p className='c-access__item-content'>Analýzy a data nás ženou kupředu. Používáme nástroje vyvinuté na míru Vašim i našim potřebám. </p>
                    </div>
                </li>
                <li className='c-access__item'
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: 1})}
                >
                    <span className='c-access__item-number'>5</span>
                    <div>
                        <h2 className='c-access__item-heading o-heading o-heading--h2'>Jednáme na rovinu</h2>
                        <p className='c-access__item-content'>Naše slovo vždy platí. Spolehlivost, transparentnost a otevřenost jsou naším základem. Společně s Vámi slavíme úspěchy, menší neúspěchy nás posouvají dále.</p>
                    </div>
                </li>
            </ol>
        </div>
    )
}

export default Access;
