import React from 'react'
import {AnchorArea, AnchorLink} from '../../Navigation';
import Typewriter from 'typewriter-effect';
import {useImages} from './images';
import Img from 'gatsby-image';

import './hero.scss'
import MouseScroll from '../../../images/mouse-scroll.svg'

const Hero = () => {
    const {bgImgSm, bgImgMd, bgImgLg, bgImgXl} = useImages();

    const bgImg = [
        bgImgSm.childImageSharp.fluid,
        {
            ...bgImgMd.childImageSharp.fluid,
            media: `(min-width: 1440px)`,
        },
        {
            ...bgImgLg.childImageSharp.fluid,
            media: `(min-width: 1920px)`,
        },
        {
            ...bgImgXl.childImageSharp.fluid,
            media: `(min-width: 2560px)`,
        },
    ];

    return (
        <AnchorArea anchorName={''}>
            <div className="c-hero" data-header-schema={'light'} data-animate-trigger={true}>
                <div className="c-hero__inner">
                    <div className="c-hero__heading"
                         data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .5})}
                    >
                        Dáváme Vašemu<br/>byznysu{' '}
                        <Typewriter
                            options={{
                                cursor: '',
                                deleteSpeed: 17,
                            }}
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString('<span class="o-highlight-red">dynamiku</span>')
                                    .pauseFor(800)
                                    .deleteChars(8)
                                    .typeString('<span class="o-highlight-red">energii</span>')
                                    .pauseFor(800)
                                    .deleteChars(8)
                                    .typeString('<span class="o-highlight-red">smysl</span>')
                                    .pauseFor(800)
                                    .deleteChars(5)
                                    .typeString('<span class="o-highlight-red">život</span>.')
                                    .start();
                            }}
                        />
                    </div>
                    <ul className="c-hero__services">
                        <li className="c-hero__service"
                            data-animate-target
                            data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                            data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .5})}
                        ><AnchorLink to={'#sluzby'} className="c-hero__heading-text c-hero__heading-text--marketing">Marketing</AnchorLink></li>
                        <li className="c-hero__service"
                            data-animate-target
                            data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                            data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .6})}
                        ><AnchorLink to={'#sluzby'} className="c-hero__heading-text c-hero__heading-text--sales">Sales</AnchorLink></li>
                        <li className="c-hero__service"
                            data-animate-target
                            data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                            data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .7})}
                        ><AnchorLink to={'#sluzby'} className="c-hero__heading-text c-hero__heading-text--cm">Crisis Management</AnchorLink></li>
                        <li className="c-hero__service"
                            data-animate-target
                            data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                            data-animate-to={JSON.stringify({y: 0, opacity: 1,  duration: 1, delay: .8})}
                        ><AnchorLink to={'#sluzby'} className="c-hero__heading-text c-hero__heading-text--consulting">Consulting</AnchorLink></li>
                    </ul>
                </div>
                <div className="c-hero__bg">
                    <Img className='o-image' fluid={bgImg} />
                </div>
                <div className="c-hero__bg-lightning">
                    <Img className='o-image' fluid={bgImg} />
                </div>
                <div className="c-hero__fog"/>
                <AnchorLink to={`#kdo-jsme`} stopAnimationsOnScroll={true}>
                    <div className="c-hero__scroll-down"
                         data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1,  duration: 1, delay: 1})}
                    >
                        <img className="c-hero__scroll-down-icon o-pulse-down" src={MouseScroll} alt=""/>
                        <p className="c-hero__scroll-down-text">skrolujte</p>
                    </div>
                </AnchorLink>
            </div>
        </AnchorArea>
    )
}

export default Hero
