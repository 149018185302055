import React, {createRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import gsap, {Power4} from 'gsap'
import {isViewportReached} from '../../helpers/isViewportReached';
import {AnchorArea} from '../Navigation';

import './section.scss'

const Section = ({children, heading, id}) => {
    const headingContainerRef = createRef();

    useEffect(() => {
        const headingContainerEl = headingContainerRef.current;

        window.addEventListener('scroll', () => {
            if (headingContainerEl && isViewportReached(headingContainerEl, {offset: -100})) {
                const {y} = headingContainerEl.getBoundingClientRect();

                const xPos = y / 6 - y / 3;

                gsap.killTweensOf(headingContainerEl);
                gsap.to(headingContainerEl, {x: xPos, duration: 1, ease: Power4.easeOut})
            }
        });
    });

    return (
        <AnchorArea anchorName={id}>
            <section className='c-section'>
                <div data-animate-trigger>
                    <div className="c-section__heading-container-wrap"
                         data-animate-target
                         data-animate-from={JSON.stringify({x: -50, opacity: 0})}
                         data-animate-to={JSON.stringify({x: 0, opacity: 1})}
                    >
                        <div className='c-section__heading-container' ref={headingContainerRef}>
                            {[...Array(5)].map((value, key) => (
                                <span key={key} className='c-section__heading c-section__heading--ghost'>{heading}</span>
                            ))}
                            <h2 className='c-section__heading'>{heading}</h2>
                            {[...Array(5)].map((value, key) => (
                                <span key={key} className='c-section__heading c-section__heading--ghost'>{heading}</span>
                            ))}
                        </div>
                    </div>
                </div>
            {children}
            </section>
        </AnchorArea>
    )
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    heading: PropTypes.string,
    id: PropTypes.string,
};

export default Section
