import React from 'react'

import './career.scss'

const Career = () => {

    return (
        <div className='c-career' data-animate-trigger>
            <p className="c-career__desc"
               data-animate-target
               data-animate-from={JSON.stringify({y: 50, opacity: 0})}
               data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
            >Talent hledáme u dlouholetých profesionálů i začínajících nadšenců. Jsme otevřeni partnerské spolupráci, novým nápadům a výzvám. Pokud se ti naše společnost líbí, pošli rovnou životopis na <a href="mailto:info@businesselements.cz" className="o-link o-link--red">info@businesselements.cz</a>. Případně, podívej se níže otevřené aktuální volné pozice.</p>

            <div className='c-career__inner'>

                <ul className='c-career__offers'>
                    <li className='c-career__offer'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                    >
                        <p className='c-career__offer-title'>Executive Bussiness Assistant</p>
                        <button className='c-career__offer-button'>Zjistit více</button>
                    </li>
                    <li className='c-career__offer'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .6})}
                    >
                        <p className='c-career__offer-title'>Regional Sales Manager</p>
                        <button className='c-career__offer-button'>Zjistit více</button>
                    </li>
                    <li className='c-career__offer'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                    >
                        <p className='c-career__offer-title'>Marketing Consultant</p>
                        <button className='c-career__offer-button'>Zjistit více</button>
                    </li>
                    <li className='c-career__offer'
                        data-animate-target
                        data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                        data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: 1})}
                    >
                        <p className='c-career__offer-title'>SEO Expert</p>
                        <button className='c-career__offer-button'>Zjistit více</button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Career;
