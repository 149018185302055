import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './services.scss'
import './service.scss'

const Services = () => {
    const data = useStaticQuery(graphql`
    query {
      marketingImage: file(relativePath: { eq: "services/marketing.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      salesImage: file(relativePath: { eq: "services/sales.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      cmImage: file(relativePath: { eq: "services/management.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      consultingImage: file(relativePath: { eq: "services/consulting.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <div className='c-services' data-animate-trigger>
            <div className='c-services__inner'>
                <div className='c-services__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
                >
                    <Link to={'/sluzby#marketing'} className="c-services__link">
                        <div className='c-service c-service--marketing'>
                            <div className='c-service__bg'>
                                <Img className='o-image' fluid={data.marketingImage.childImageSharp.fluid} imgStyle={{objectPosition: '77% 50%'}}/>
                            </div>
                            <div className='c-service__body'>
                                <p className="c-service__no">01</p>
                                <h3 className="c-service__heading o-heading">Marketing</h3>
                                <p className="c-service__content">Budujme společně povědomí o Vaší značce a získejte svoje zákazníky efektivně.</p>
                                <p className="c-service__cta">Chci vědet víc</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='c-services__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                >
                    <Link to={'/sluzby#sales'} className="c-services__link">
                        <div className='c-service c-service--sales'>
                            <div className='c-service__bg'>
                                <Img className='o-image' fluid={data.salesImage.childImageSharp.fluid} imgStyle={{objectPosition: '75% 50%'}}/>
                            </div>
                            <div className='c-service__body'>
                                <p className="c-service__no">02</p>
                                <h3 className="c-service__heading o-heading">Sales</h3>
                                <p className="c-service__content">Analyzujme společně Vaší obchodní strategii a zlepšeme výkon Vašich obchodních týmů</p>
                                <p className="c-service__cta">Chci vědet víc</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='c-services__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .6})}
                >
                    <Link to={'/sluzby#krizovy-management'} className="c-services__link">
                        <div className='c-service c-service--crisis-management'>
                            <div className='c-service__bg'>
                                <Img className='o-image' fluid={data.cmImage.childImageSharp.fluid} imgStyle={{objectPosition: '80% 50%'}}/>
                            </div>
                            <div className='c-service__body'>
                                <p className="c-service__no">03</p>
                                <h3 className="c-service__heading o-heading">Krizový management</h3>
                                <p className="c-service__content">V krizi není nikdy pozdě udělat radikální kroky a změny vedoucí k opětovnému růstu firmy či nastavení vhodných procesů.</p>
                                <p className="c-service__cta">Chci vědet víc</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='c-services__item'
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .8})}
                >
                    <Link to={'/sluzby#consulting'} className="c-services__link">
                        <div className='c-service c-service--consulting'>
                            <div className='c-service__bg'>
                                <Img className='o-image' fluid={data.consultingImage.childImageSharp.fluid} imgStyle={{objectPosition: '87% 50%'}}/>
                            </div>
                            <div className='c-service__body'>
                                <p className="c-service__no">04</p>
                                <h3 className="c-service__heading o-heading">Consulting</h3>
                                <p className="c-service__content">Získejte zpětnou vazbu a akční kroky na management Vaší společnosti, marketingové či obchodní strategie nebo na nábor a získávání nových sil do Vašich týmů.</p>
                                <p className="c-service__cta">Chci vědet víc</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Services;
